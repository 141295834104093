<template>
  <div class="p-6">
    <a-form layout="inline" :form="form" class="form-label-4">
      <a-form-item label="日期">
        <a-range-picker
          format="YYYY-MM"
          :value="month"
          :mode="mode"
          @panelChange="panelChange"
          @change="monthChange"
          @openChange="openChange"
        />
      </a-form-item>
      <a-form-item label="项目">
        <a-select-pro
          class="form-control"
          v-decorator="['projectCode']"
          placeholder="请选择"
          allowClear
          showSearch
          :options="projectList"
          @change="doQuery(1)"
          :dropdownMatchSelectWidth="false"
        ></a-select-pro>
      </a-form-item>
      <a-form-item label="班组">
        <a-select
          class="form-control"
          v-decorator="['leaderId']"
          placeholder="请选择"
          allowClear
          showSearch
          :options="leaderList"
          @change="doQuery(1)"
        ></a-select>
      </a-form-item>
    </a-form>

    <br />
    <span style="color: black">
       工人姓名：{{this.userName}}
      &emsp;&emsp;&emsp;
       身份证号：{{this.cardId}}

    </span>
    <br />

    <div class="mt-6">
      <a-table
        rowKey="id"
        bordered
        :loading="loading"
        :columns="tableColumns"
        :dataSource="dataSource"
        :pagination="false"
        :scroll="{ x: 1940 }"
        size="small"
      ></a-table>
      <div class="flex text-lg" v-if="attendanceHourSum">
        <div style="width: 80px; text-align: center">汇总</div>
        <div>
          出勤天数:
          <span class="text-black">{{ attendanceDaySum }}</span>
        </div>
        <div class="ml-10">
          出勤小时:
          <span class="text-black">{{ attendanceHourSum }}</span>
        </div>
      </div>
      <base-pagination
        class="mt-6"
        :totalCount.sync="totalCount"
        :pageNo.sync="pageNo"
        :pageSize.sync="pageSize"
        @change="pageChange"
        size="small"
      />
    </div>
  </div>
</template>

<script>
import { getProjectListByCard, getLeaderListByCard } from '@/service/getData'
import { queryViewMixin } from '@/utils/mixin'

import ASelectPro from '@/components/SelectPro.vue'
export default {
  components: {
    ASelectPro,
  },
  mixins: [queryViewMixin],
  data() {
    const dayCols = []
    for (let i = 1; i <= 31; i++) {
      dayCols.push({
        title: i,
        dataIndex: `attendanceInfo.${i}`,
        width: 33,
        align: 'center',
        customRender: (text) => {
          return (
            <div>
              {text === 0 ? (
                <a-icon type="close" class="text-red-500" title="无考勤"></a-icon>
              ) : (
                <a-icon type="check" class="text-green-500" title="有效考勤"></a-icon>
              )}
            </div>
          )
        },
      })
    }
    const tableColumns = [
      {
        title: '考勤月份',
        width: 80,
        align: 'center',
        dataIndex: 'attendanceMonth',
        fixed: 'left',
      },
      {
        title: '项目',
        width: '12%',
        align: 'left',
        dataIndex: 'projectName',
      },
      {
        title: '班组',
        width: '6%',
        align: 'center',
        dataIndex: 'leaderName',
      },
      ...dayCols,

      {
        title: '总出勤天数',
        width: '6%',
        align: 'center',
        dataIndex: 'attendanceDays',
      },
      {
        title: '总出勤小时',
        width: '6%',
        align: 'center',
        dataIndex: 'attendanceHours',
      },
      {
        title: '核减天数',
        width: '6%',
        align: 'center',
        dataIndex: 'lockDays',
      },
      {
        title: '核减流程id',
        width: '6%',
        align: 'center',
        dataIndex: 'requestId',
      },
      {
        title: '核减来源',
        width: '8%',
        align: 'center',
        dataIndex: 'source',
      },
      {
        title: '原始记录',
        width: 100,
        align: 'center',
        fixed: 'right',
        customRender: (text, row) => {
          return (
            <a
              onClick={() => {
                this.checkRow(row)
              }}
            >
              查看
            </a>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],

      projectList: [],
      leaderList: [],
      month: [],
      mode: ['month', 'month'],
      attendanceDaySum: 0,
      attendanceHourSum: 0,

      cardId:"",
      userName:"",
    }
  },
  methods: {
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        await this.$nextTick()
        const params = {
          ...this.getPageParams(_pageNo, _pageSize),
          cardId: this.$route.query.cardId,
          month: this.month,
        }
        const res = await this.$axios.post(
          '/report/evidence/chain/attendance/list',
          this.formatTimeRangeParams(params, 'month', { format: 'YYYY-MM' })
        )
        const { pageSize, pageNo, totalCount, list } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
        this.attendanceDaySum = res.data.attendanceDaySum
        this.attendanceHourSum = res.data.attendanceHourSum
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    panelChange(month, mode) {
      this.month = month
      this.mode = [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]]
    },
    monthChange(month) {
      this.month = month
      this.doQuery(1)
    },
    openChange(open) {
      if (!open) {
        this.doQuery(1)
      }
    },
    checkRow(row) {
      const url = `https://bbfr.cqvantai.com/WebReport/decision/view/report?viewlet=attendence_origin_record.cpt&sfz=${row.cardNumber}&qj=${row.attendanceMonth}&prj=${row.projectCode}&__pi__=false`
      window.open(url)
    },
  },
  async mounted() {
    const { cardId, projectCode, leaderId } = this.$route.query
    const param={ cardId, projectCode, leaderId }
    const res = await this.$axios.post(`/report/evidence/chain/worker/Info`, param)
    this.userName=res.data.username
    this.cardId=cardId

    this.projectList = await getProjectListByCard({ cardId })
    this.leaderList = await getLeaderListByCard({ cardId })
    this.doQuery()
  },
}
</script>

<style lang="less" scoped></style>
